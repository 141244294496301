.spinner {
  border: 3px solid #ffdc74;
  border-right: 3px solid transparent;
  border-radius: 50%;
  animation: rotate 2.5s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
