body{
	font-family: 'Lato';
    font-weight: normal;
    font-style: normal;
    overflow-x: hidden;
    font-size: 14px;
    color: #000000;
}

h1,h2,h3,h4,h5,h6{
	margin: 0px;
	padding: 0px;
	font-weight: normal;
}
p{
	margin: 0px;
	padding: 0px;
}
ul{
	margin: 0px;
	padding:0px;
	list-style: none;
}
a{
	text-decoration: none !important;
	outline: none !important;
}
.d-new{
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    flex-wrap: wrap;
}
.d-align{
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    flex-wrap: wrap;
}
.d-new-a{
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
     -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    flex-wrap: wrap;
}
/*------------------ Account Page Css Start ------------------*/
.sidebar{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 260px;
    background: #37517e;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
}
.icon_active{
    display: none;
}
.logo{
    text-align: center;
    padding: 25px;
}
.logo img{
    max-width: 180px;
}
.menu{
    margin-top: 20px;
}
.menu li a{
    padding: 13px 25px;
    display: flex;
    align-items: center;
    border-left: 3px solid transparent;
    color: #fff;
    font-weight: 500;
    margin-bottom: 1px;
}
.menu li.active a,.menu li a:hover{
    background: #293d5f;
    color: #00b9ff;
    border-left-color: #00b9ff;
}
.menu li.active a .icon_normal,.menu li a:hover .icon_normal{
    display: none;
}
.menu li.active a .icon_active,.menu li a:hover .icon_active{
    display: block;
}
.menu_icon{
    width: 26px;
    margin-right: 10px;
}
.menu_icon img{
    max-width: 100%;
}
.content{
    margin-left: 260px;
    width: calc(100% - 260px);
    padding: 20px;
    min-height: 100vh;
    background: #f2f2f2;
}
.page_header{
    position: fixed;
    top: 0px;
    left: 260px;
    width: calc(100% - 260px);
    background: #fff;
    box-shadow: 0px 2px 10px rgba(0,0,0,0.06);
    padding: 25px 20px;
    background: #fff;
    z-index: 200;
}
.page_header h3{
    font-size: 26px;
}
.page_header h3 span{
    font-weight: 900;
}
.singout{
    margin-left: 30px;
}
.singout {
    font-weight: 900;
    color: #c1007a;
}
.singout:hover{
    color: #000;
    cursor: pointer;
}
.notification{
    position: relative;
}
.notification img{
    width: 25px;
}
.notification span{
    position: absolute;
    font-size: 10px;
    font-weight: 600;
    color: #fff;
    background: #ff0000;
    border-radius: 50%;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 3px;
    right: -3px;
}
.m-t-80{
    margin-top: 80px;
}
.upgrade_plus{
    background: #c1007a;
    padding: 20px;
}
.upgrade_plus p{
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    width: calc(100% - 220px);
}
.btn,.form-control{
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    -ms-box-shadow: none !important;
    box-shadow: none !important;
}
.btn-white{
    background: #fff;
    font-weight: bold;
    letter-spacing: 1px;
    color: #000000 !important;
    font-size: 13px;
    border-radius: 0px;
    border: 0px;
    padding: 10px 30px;
}
.btn-white:hover{
    background: #000;
    color: #fff !important;
}
.white_box{
    /* opacity: 0.6; */
    background-color: #fff;
    /* background-color:rgba(234, 228, 235, 0.021); */
    padding: 20px 25px;
    margin-top: 20px;
}
.account_white_box{
    opacity: 0.6;
    background-color: #ffffff;
    padding: 20px 25px;
    margin-top: 20px; 
}

.background-image{
    /* margin-top: 30px; */
    background-image: url('./bg2.jpg');
    background-position: center;
    background-size: cover;
    width: auto;
    height: auto;
}
.width-60{
    width: calc(60% - 10px);
}
.width-40{
    width: calc(40% - 10px);
}
.width-100{
    width: 100%;
}
.title{
    margin-bottom: 30px;
}
.title h3{
    font-size: 18px;
    font-weight: 600;
    position: relative;
    padding-bottom: 5px;
}
.title h3:after{
    position: absolute;
    content: "";
    height: 2px;
    width: 85px;
    background: #ffe187;
    left: 0px;
    bottom: 0px;
}
.card_point li{
    display: flex;
    align-items: center;
    margin-bottom: 22px;
    flex-wrap: wrap;
}
.card_point li:last-child{
    margin-bottom: 0px;
}
.card_point li p{
    font-weight: 500;
    width: 55%;
}
.card_point_text{
    width: 45%;
}
.card_point span{
    display: inline-block;
    font-weight: 900;
}
.card_point_text img{
    width: 25px;
}
.edit_details{
    color: #00b9ff;
    font-weight: 600;
    border-bottom: 1px solid #202020;
}
.edit_details:hover{
    color: #000;
}
.fund_load li{
    padding: 25px 0px;
    border-bottom: 1px solid #e2e2e2;
}
.fund_load li:first-child{
    padding-top: 10px;
}
.fund_load li:last-child{
    padding-bottom: 10px;
    border-bottom:0px;
}
.fund_load_icon{
    width: 45px;
}
.fund_load_icon img{
    max-width: 100%;
}
.fund_load_text{
    width: calc(100% - 45px);
    padding-left: 25px;
}
.fund_load_text h4{
    font-weight: bold;
    font-size: 18px;
}
.fund_load_text p{
    font-size: 13.5px;
    font-weight: 500;
    margin-top: 15px;
}
.btn-blue{
    background: #00b9ff;
    font-weight: bold;
    letter-spacing: 1px;
    color: #fff !important;
    font-size: 13px;
    border-radius: 0px;
    border: 0px;
    padding: 10px 20px;
    min-width: 157px;
    text-transform: uppercase;
}
.btn-blue:hover{
    background: #37517e;
}
.fund_load_text .btn{
    margin-top: 15px;
}
/*------------------ Account Page Css End ------------------*/
/*------------------ Load Fund Bank Page Css Start ------------------*/
.fund_load_bank_icon{
    width: 45px;
}
.fund_load_bank_icon img{
    max-width: 100%;
}
.fund_load_bank_info{
    width: calc(100% - 45px);
    padding-left: 25px;
}
.font-black{
    font-weight: 900 !important;
}
.fund_load_bank_info .title{
    margin-bottom: 20px;
}
.fund_load_bank_text p{
    font-weight: 500;
    margin-bottom: 20px;
    line-height: 22px;
}
.fund_load_bank_point{
    margin-top: 40px;
}
.fund_load_bank_point li{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 25px;
}
.fund_load_bank_point li p{
    font-weight: 500;
    width: 35%;
}
.fund_load_bank_point li span{
    font-weight: 900;
    border-bottom: 1px solid #cccccc;
    display: inline-block;
    min-width: 30%;
    padding-bottom: 2px;
}
.highlight_text{
    color: #f23538;
}
.note_text{
    margin-top: 40px;
}
.note_text h5{
    font-weight: 900;
    font-size: 17px;
    line-height: 25px;
}
.note_text h5 span{
    color: #c1007a;
}
/*------------------ Load Fund Bank Page Css End ------------------*/
/*------------------ Load Fund Debit Page Css Start ------------------*/
.fund_load_card{
    box-shadow: 0px 0px 25px rgba(0,0,0,0.1);
    max-width: 70%;
    padding: 30px;
    margin-top: 40px;
}
.fund_load_card li{
    margin-bottom: 25px;
}
.fund_load_card li:last-child{
    margin-bottom: 0px;
}
.fund_load_card p{
    font-weight: 500;
    width: 40%;
}
.fund_load_card p span{
    color: #c1007a;
}
.fund_load_card_info{
    position: relative;
}
.fund_load_card_info .form-control{
    padding: 4px 0px;
    height: auto !important;
    min-height: initial;
    border: 0px;
    border-radius: 0px;
    border-bottom: 1px solid #cccccc;
    background: transparent;
    font-weight: 900;
    font-size: 16px;
}
.fund_load_card_info select.form-control{
    -moz-appearance:none;
    -webkit-appearance:none;
    appearance:none;
    background: url(../images/icon_arrow_down.png) no-repeat right transparent;
    background-size: 13px;
    padding-right: 30px;
}
.cvv_input{
    width: 45px;
}
.fund_load_card_info a{
    color: #c1007a;
    font-weight: 900;
    font-size: 13px;
    display: inline-block;
    margin-left: 15px;
}
.fund_load_card_info a:hover{
    color: #000;
}
.card_number .form-control{
    padding-right: 120px !important;
}
.card_number img{
    width: 30px;
    position: absolute;
    top: 0px;
    right: 22px;
    bottom: 0px;
    margin: auto;
}
.load_funds_btn{
    margin-top: 40px;
}
/*------------------ Load Fund Debit Page Css End ------------------*/
/*------------------ Payment Request Page Css Start ------------------*/
.m-b-20{
    margin-bottom: 20px;
}
.fund_load_bank_text p b{
    font-weight: 900;
}
.ava_blance{
    margin-top: 35px;
}
.ava_blance h3{
    font-weight: 900;
    color: #c1007a;
    font-size: 17px;
    margin-left: 50px;
}
.gray_box{
    background: #f7f7f7;
    margin-top: 13px;
    padding: 20px;
}
.sub-title{
    font-weight: 900;
    font-size: 17px;
}
.payment_type_radio{
    margin-top: 20px;
}
.ctm_radio {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 0px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.ctm_radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.ctm_radio .checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: 20px;
    width: 20px;
    border: 1px solid #c1007a;
    border-radius: 50%;
}
.ctm_radio .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
.ctm_radio input:checked~.checkmark:after {
    display: block;
}
.ctm_radio .checkmark:after {
    top: 4px;
    left: 4px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #c1007a;
}
.form-control-iput{
    border-radius: 0px;
    border: 0px;
    background: transparent !important;
    border-bottom: 1px solid #ccc;
    width: 280px;
    color: #000;
    font-weight: 900;
}
.form-control-iput::-webkit-input-placeholder {
    font-weight: normal;
}
.form-control-iput:-ms-input-placeholder {
    font-weight: normal;
}
.form-control-iput::placeholder {
    font-weight: normal;
}
.payment_form{
    margin-top: 20px;
}
.payment_form li{
    margin-bottom: 20px;
}
.payment_form li:last-child{
    margin-bottom: 0px;
}
.label_name{
    font-weight: 500;
} 
.label_name span{
    color: #c1007a;
}
.sort_input{
    margin-left: 10px;
}
.sort_input:first-child{
    margin-left: 0px;
}
.label_name{
    width: 35%;
}
.sort_input .form-control-iput{
    width: 86px;
    text-align: center;
}
.form_input{
    position: relative;
}
.form_input .sortcodedemo {
    font-weight: 500;
    margin-left: 10px;
}
.width100{
    width: calc(100% - 35%);
}
.width100 .form-control{
    width: 100%
}
.total_character span{
    color: #c1007a;
    font-size: 12px;
    margin-top: 5px;
    display: inline-block;
}
.btn_submit{
    margin-top: 20px;
}
.btn_submit .btn{
    min-width: initial;
}
.paymant_type_info{
    display: none;
}
.paymant_type_info.active{
    display: block;
}
.form_input_date img{
    position: absolute;
    right: 10px;
    top: 9px;
    margin: auto;
    width: 20px;
}
.form_input_date .form-control-iput{
    width: 170px;
    font-weight: 900;
}
.border-top-new {
    border-top: 1px solid #eaeaea;
    padding-top: 15px;
    margin-top: 40px;
}
select.form-control-iput{
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: url(../images/icon_arrow_down.png) no-repeat right transparent !important;
    background-size: 13px !important;
    padding-right: 30px;
}
/*------------------ Payment Request Page Css End ------------------*/
/*------------------ Register Page Css Start ------------------*/
.form_input .card_number .form-control{
    width: 280px;
}
.part50{
    width: calc(140px - 7.5px);
    margin-left: 15px
}
.part50:first-child{
    margin-left: 0px
}
.part50  .form-control{
    width: 100% !important;
}
.registered_contact{
    margin-top: 30px;
}
.registered_contact i{
    font-weight: 500;
}
.registered_contact a{
    color: #c1007a;
}
.btn_submit_register{
    margin-top: 30px;
}
/*------------------ Register Page Css End ------------------*/
/*------------------ Forget Password Page Css Start ------------------*/
.forgot_outer,.forgot_bg_img{
    height: 100%;
}
.forgot_bg_img img{
    width: 100%;
    height: 100%;
}
.forgot_left{
    width: 45%;
    position: relative;
}
.forgot_logo{
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.forgot_logo img{
    max-width: 200px;
    margin-top: 150px;
    margin-left: -90px;
}
.forgot_right{
    width: 55%;
    display: flex;
    padding: 20px 8%;
    flex-direction: column;
    justify-content: center;
}
.forgot_right h3{
    font-weight: 900;
    font-size: 44px;
    margin-bottom: 15px;
}
.forget_group{
    margin-top: 30px;
}
.forget_group > label{
    font-weight: bold;
    margin-bottom: 8px;
    font-size: 16px;
}
.forget_group .form-control-iput{
    width: 100% !important
}
.forget_btn{
    margin-top: 30px;
}
.forget_btn .btn{
    min-width: initial;
}
.forget_part{
    width: calc(33.33% - 15px);
}
.return_login{
    margin-top: 30px;
}
.return_login p{
    font-weight: 500;
}
.return_login p a{
    font-weight: 900;
    color: #c1007a;
}
.return_login p a:hover{
    color: #000;
}
/*------------------ Forget Password Page Css End ------------------*/
header{
    display: none;
}
.header_inner{
    position: fixed;
    top: 0px;
    left: 0px;
    padding: 10px 15px;
    width: 100%;
    background: #37517e;
    z-index: 11;
}
.h_logo img{
    max-width: 100px;
}
.toggle_menu{
    font-size: 23px;
    line-height: 23px;
    margin-right: 10px;
    color: #fff;
}
.toggle_menu label{
    margin-bottom: 0px;
    cursor: pointer;
    -webkit-transform: scale(1.2,1);
    -moz-transform: scale(1.2,1);
    -ms-transform: scale(1.2,1);
    transform: scale(1.2,1);
}
.mobile_text{
    display: none;
}
.mobile_text h3{
    font-size: 26px;
}
.mobile_text h3 span{
    font-weight: 900;
}
@media(min-width: 1200px) and (max-width: 1299px){
    .width-60 {
        width: calc(55% - 10px);
    }
    .width-40 {
        width: calc(45% - 10px);
    }
    /*------------------ Load Fund Bank Page Css Start ------------------*/
    .fund_load_bank_point li span {
        min-width: 40%;
    }
    /*------------------ Load Fund Bank Page Css End ------------------*/
    /*------------------ Load Fund Debit Page Css Start ------------------*/
    .fund_load_card {
        max-width:80%;
    }
    /*------------------ Load Fund Debit Page Css End ------------------*/
    /*------------------ Payment Request Page Css Start ------------------*/
    .ctm_radio {
        font-size: 14px;
        padding-left: 28px;
    }
    .ctm_radio .checkmark {
        top: 0px;
    }
    .form_input {
        width: 65%;
    }
    .form_input .sortcodedemo {
        font-size: 12px;
    }
    /*------------------ Payment Request Page Css End ------------------*/
    /*------------------ Payment Request Page Css End ------------------*/
    .register_form .form_input{
        width: 280px;
    }
    /*------------------ Register Page Css End ------------------*/
    /*------------------ Forget Password Page Css Start ------------------*/
    
    .forgot_outer, .forgot_bg_img {
        min-height: 100vh;
        height: initial;
    }
    .forgot_logo img {
        margin-left: -39px;
    }
    /*------------------ Forget Password Page Css End ------------------*/
}
@media(min-width: 992px) and (max-width: 1199px){
    body {
        font-size: 13px;
    }
    .sidebar {
        width: 240px;
    }
    .logo img {
        max-width: 150px;
    }
    .menu li a {
        padding: 10px 15px;
    }
    .menu_icon {
        width: 20px;
    }
    .content {
        margin-left: 240px;
        width: calc(100% - 240px);
        padding: 15px;
    }
    .page_header {
        left: 240px;
        width: calc(100% - 240px);
        padding: 25px 15px;
    }
    .width-60 {
        width: calc(56% - 10px);
    }
    .width-40 {
        width: calc(44% - 10px);
    }
    /*------------------ Load Fund Bank Page Css Start ------------------*/
    .fund_load_bank_point li span {
        min-width: 50%;
    }
    /*------------------ Load Fund Bank Page Css End ------------------*/
    /*------------------ Load Fund Debit Page Css Start ------------------*/
    .fund_load_card {
        max-width:100%;
    }
    /*------------------ Load Fund Debit Page Css End ------------------*/
    /*------------------ Payment Request Page Css Start ------------------*/
    .ctm_radio{
        margin-bottom: 10px;
    }
    .form_input {
        width: 65%;
    }
    .form_input .sortcodedemo {
        margin-left: 0px;
        width: 100%;
        margin-top: 10px;
    }
    /*------------------ Payment Request Page Css End ------------------*/
    /*------------------ Payment Request Page Css End ------------------*/
    .register_form .form_input{
        width: 280px;
    }
    /*------------------ Register Page Css End ------------------*/
    /*------------------ Forget Password Page Css Start ------------------*/
    .forgot_right h3 {
        font-size: 33px;
    }
    .forgot_right {
       padding: 20px 5%;
    }
    .forget_group {
        margin-top: 20px;
    }
    .forgot_outer, .forgot_bg_img {
        min-height: 100vh;
        height: initial;
    }
    .forgot_logo img {
        margin-left: -39px;
    }
    /*------------------ Forget Password Page Css End ------------------*/
}
@media(min-width: 768px) and (max-width: 991px){
    body {
        font-size: 13px;
    }
    .sidebar {
        width: 240px;
    }
    .logo img {
        max-width: 150px;
    }
    .menu li a {
        padding: 10px 15px;
    }
    .menu_icon {
        width: 20px;
    }
    .content {
        margin-left: 240px;
        width: calc(100% - 240px);
        padding: 15px;
    }
    .page_header {
        left: 240px;
        width: calc(100% - 240px);
        padding: 25px 15px;
    }
    .upgrade_plus {
        padding: 10px;
    }
    .btn-white {
        font-size: 12px;
        padding: 8px 15px;
    }
    .upgrade_plus p {
        font-size: 16px;
        width: calc(100% - 170px);
    }
    .width-40 {
        width: 100%;
    }
    .width-60 {
        width: 100%;
    }
    /*------------------ Load Fund Bank Page Css Start ------------------*/
    .fund_load_bank_point li span {
        min-width: 60%;
    }
    /*------------------ Load Fund Bank Page Css End ------------------*/
    /*------------------ Load Fund Debit Page Css Start ------------------*/
    .fund_load_card {
        max-width: calc(100% + 70px);
        padding: 15px;
        margin:20px 0px 0px -70px;
    }
    .fund_load_card p {
        width: 44%;
    }
    /*------------------ Load Fund Debit Page Css End ------------------*/
    /*------------------ Payment Request Page Css Start ------------------*/
    .payment_type_radio {
        margin-top: 10px;
    }
    .ctm_radio{
        font-size: 13px;
        margin-bottom: 10px;
        width: 100%;
    }
    .ctm_radio .checkmark {
        top: 0px;
    }
    .label_name {
        width: 100%;
    }
    .form_input {
        width: 100%;
    }
    .form-control-iput {
        width: 100%;
        font-size: 14px;
    }
    .sort_input {
        width: calc(33.3% - 7px);
    }
    .sort_input .form-control-iput {
        width: 100%;
    }
    .form_input .sortcodedemo {
        margin-left: 0px;
        margin-top: 7px;
    }
    .form_input_date .form-control-iput {
        width: 100%;
    }
    /*------------------ Payment Request Page Css End ------------------*/
    .form_input .card_number .form-control {
        width: 100%;
    }
    .part50 {
        width: calc(50% - 7.5px);
    }
    .btn_submit_register {
        margin-top: 20px;
    }
    /*------------------ Register Page Css End ------------------*/
    /*------------------ Forget Password Page Css Start ------------------*/
    .forgot_bg_img{
        display: none;
    }
    .forgot_left {
        width: 100%;
        position: relative;
        background: #37517e;
        padding: 50px 10px;
        text-align: center;
    }
    .forgot_logo {
        position: relative;
        top: initial;
        left: initial;
        bottom: initial;
        right: initial;
        display: initial;
    }
    .forgot_logo img {
        max-width: 160px;
        margin-top: 0px;
        margin-left: 0px;
    }
    .forgot_right {
        width: 100%;
        padding: 20px 15px;
    }
    .forgot_right h3 {
        font-size: 27px;
        margin-bottom: 12px;
    }
    .forget_group {
        margin-top: 20px;
    }
    .forget_part {
        width: calc(33.33% - 10px);
    }
    .forget_btn {
        margin-top: 20px;
    }
    .return_login {
        margin-top: 20px;
    }
    .form-control-iput {
        padding: 10px 0px;
    }
    /*------------------ Forget Password Page Css End ------------------*/
}
@media(max-width: 767px){
    body {
        font-size: 13px;
    }
    .mobile_text {
        display: block;
        margin-bottom: 20px;
    }
    .mobile_text h3 {
        font-size: 20px;
    }
    .sidebar {
        width: 100%;
        height: calc(100% - 79px);
        top: 79px;
        left: -100%;
        z-index: 8;
        border-top: 1px solid rgba(255,255,255,0.4);
        -webkit-transition: all 0.3s linear;
        -moz-transition: all 0.3s linear;
        -ms-transition: all 0.3s linear;
        transition: all 0.3s linear;
    }
    .sidebar.active {
        left: 0px;
    }
    .page_header {
        display: none;
    }
    .content {
        margin-left: 0px;
        width: 100%;
        padding: 15px;
    }
    .logo{
        display: none;
    }
    .menu {
        margin-top: 0px;
    }
    .menu li a {
        padding: 10px 15px;
    }
    .menu_icon {
        width: 20px;
    }
    header {
        display: block;
    }
    .singout a {
        color: #fff !important;
    }
    .singout {
        margin-left: 24px;
    }
    .upgrade_plus {
        padding: 15px;
        justify-content: center;
    }
    .upgrade_plus p {
        font-size: 16px;
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
    }
    .btn-white {
        font-size: 12px;
        padding: 8px 23px;
    }
    .width-40 {
        width: 100%;
    }
    .width-60 {
        width: 100%;
    }
    .white_box {
        padding: 15px;
        margin-top: 15px;
    }
    .title h3 {
        font-size: 16px;
    }
    .title h3:after {
        width: 70px;
    }
    .title {
        margin-bottom: 20px;
    }
    .card_point li p {
        width: 53%;
    }
    .card_point_text {
        width: 47%;
    }
    .fund_load_icon {
        width: 30px;
    }
    .fund_load_text {
        width: calc(100% - 30px);
        padding-left: 15px;
    }
    .fund_load_text h4 {
        font-size: 17px;
    }
    .fund_load_text p {
        font-size: 13px;
        margin-top: 10px;
        line-height: 23px;
    }
    .btn-blue {
        font-size: 13px;
        padding: 8px 10px;
        min-width: 140px;
    }
    .fund_load_text .btn {
        margin-top: 10px;
    }
    /*------------------ Load Fund Bank Page Css Start ------------------*/
    .fund_load_bank_icon {
        width: 30px;
    }
    .fund_load_bank_info {
        width: calc(100% - 30px);
        padding-left: 10px;
    }
    .fund_load_bank_info .title {
        margin-bottom: 15px;
    }
    .fund_load_bank_text p {
        margin-bottom: 10px;
        line-height: 23px;
    }
    .fund_load_bank_point {
        margin-top: 20px;
    }
    .fund_load_bank_point li p {
        width: 100%;
        margin-bottom: 7px;
    }
    .fund_load_bank_point li span {
        min-width: 100%;
    }
    .fund_load_bank_point li {
        margin-bottom: 15px;
    }
    .note_text {
        margin-top: 20px;
    }
    .note_text h5 {
        font-size: 14px;
        line-height: 23px;
    }
    .note_text h5 br{
        display: none;
    }
    /*------------------ Load Fund Bank Page Css End ------------------*/
    /*------------------ Load Fund Debit Page Css Start ------------------*/
    .fund_load_card {
        max-width: calc(100% + 45px);
        padding: 15px;
        margin:20px 0px 0px -45px;
    }
    .fund_load_card p {
        width: 100%;
        margin-bottom: 5px;
    }
    /*------------------ Load Fund Debit Page Css End ------------------*/
    /*------------------ Payment Request Page Css Start ------------------*/
    .ava_blance {
        margin-top: 20px;
    }
    .ava_blance h3 {
        font-size: 15px;
        margin-left: 15px;
    }
    .gray_box {
        padding: 10px;
    }
    .sub-title {
        font-size: 14px;
    }
    .payment_type_radio {
        margin-top: 10px;
    }
    .ctm_radio{
        font-size: 13px;
        margin-bottom: 10px;
        width: 100%;
    }
    .ctm_radio .checkmark {
        top: 0px;
    }
    .label_name {
        width: 100%;
    }
    .form_input {
        width: 100%;
    }
    .form-control-iput {
        width: 100%;
        font-size: 14px;
    }
    .sort_input {
        width: calc(33.3% - 7px);
    }
    .sort_input .form-control-iput {
        width: 100%;
    }
    .form_input .sortcodedemo {
        margin-left: 0px;
        margin-top: 7px;
    }
    .form_input_date .form-control-iput {
        width: 100%;
    }
    /*------------------ Payment Request Page Css End ------------------*/
    /*------------------ Register Page Css Start ------------------*/
    .form_input .card_number .form-control {
        width: 100%;
    }
    .part50 {
        width: calc(50% - 7.5px);
    }
    .btn_submit_register {
        margin-top: 20px;
    }
    /*------------------ Register Page Css End ------------------*/
    /*------------------ Forget Password Page Css Start ------------------*/
    .forgot_bg_img{
        display: none;
    }
    .forgot_left {
        width: 100%;
        position: relative;
        background: #37517e;
        padding: 50px 10px;
        text-align: center;
    }
    .forgot_logo {
        position: relative;
        top: initial;
        left: initial;
        bottom: initial;
        right: initial;
        display: initial;
    }
    .forgot_logo img {
        max-width: 160px;
        margin-top: 0px;
        margin-left: 0px;
    }
    .forgot_right {
        width: 100%;
        padding: 20px 15px;
    }
    .forgot_right h3 {
        font-size: 27px;
        margin-bottom: 12px;
    }
    .forget_group {
        margin-top: 20px;
    }
    .forget_part {
        width: calc(33.33% - 10px);
    }
    .forget_btn {
        margin-top: 20px;
    }
    .return_login {
        margin-top: 20px;
    }
    .form-control-iput {
        padding: 10px 0px;
    }
    /*------------------ Forget Password Page Css End ------------------*/
}
.inner_content {
    padding: 20px 20px;
    background-color: #F7F7F7;
	margin-bottom:20px;
}
.sub_title h6 {
    color: #252525;
    font-weight: 900;
    margin-bottom: 10px;
}
.inner_content p {
    margin-bottom: 20px;
}
.inner_content .btn {
    border-radius: 0px;
}
.inner_content a {
    white-space: normal;
}
.direct_debit_card
{
    box-shadow: none;
    max-width: 100%;
    margin-top: 20px;
	padding:0px;
}
.edit_info .form-control{
	padding:6px 0px;
}
.blue_note {
    background-color: #37517E;
    padding: 5px 10px;
    text-align: center;
    color: #fff;
}
.blue_note a {
    color: #fff;
}
.post_ofc_bank_point
{
	    margin-top: 40px;
}
.fund_load_bank_outer.d-flex.flex-wrap a {
    white-space: normal;
}
.post_ofc_bank_point li {
    margin-bottom: 25px;
    list-style: square;
    margin-left: 20px;
}
a.forget_link {
    position: absolute;
    right: 0;
    top: 0;
    color: #37517e;
}
.login_group .form-control-iput
{
	    font-style: italic;
}
.span_tag p {
    color: #c1007a;
    font-weight: bold;
    font-size: 17px;
}
.btn-dark-blue {
    background: #37517E;
    font-weight: bold;
    letter-spacing: 1px;
    color: #fff !important;
    font-size: 13px;
    border-radius: 0px;
    border: 0px;
    padding: 10px 15px;
   
    text-transform: uppercase;
}
.table-reponsive.table_sec {
    margin-top: 50px;
    box-shadow: -1px 1px 3px 3px #f9f8f8;
}

.table-reponsive.table_sec thead {
    background-color: #F2F2F2;
   
}
.table_sec td
{
	text-align:center;
}

.col-red {
    color: red;
}

.update_data p {
    padding-bottom: 20px;
}
.table_data
{
	position:relative;
	
}
.table_data p
{
    vertical-align: middle;
    top: 50%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);

}
.doc_sec
{
	box-shadow: -1px 1px 3px 3px #f9f8f8;
	padding:20px 50px;
}
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.custom_btn {
    border: 2px dashed #d0cece;
    color: #d0cece;
    background-color: white;
    padding: 8px 42px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: bold;
    border-radius: 0px;
}
.doc_sec p {
    font-weight: bold;
}
.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.table_data {
    background-image: url(../images/security_icon.png);
    background-repeat: no-repeat;
    background-position: center;
	min-height:284px;
}
@media (max-width: 767px)
{
	.direct_debit_card
	{
		margin:10px !important;
	}
}
.table-responsive.table_sec {
    margin-top: 45px;
}

.error-message {
    color: tomato
}

.clickable-text {
    color: #fd941c;
    cursor: pointer;
    user-select: none;
}

.clickable-text:hover{
    text-decoration: underline;
}

.clickable-text:active {
    color: #ffe187;
}